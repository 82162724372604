import { BulletList } from '../../components/atoms/BulletList';
import { Copy, H1, H2 } from '../../components/atoms/Typography';
import { CopyColoredSpan } from '../../components/atoms/Typography/Copy';

const Security = () => {
  return (
    <>
      <H1>Security</H1>

      <H2>Internal Access</H2>
      <Copy styleLevel={2}>
        At JobsBoard<CopyColoredSpan>.io</CopyColoredSpan>, even internal access to data is highly restricted and
        continuously monitored. All personnel granted production server access are not only thoroughly vetted but are
        authorized by executive team members who are legally bound to protect your data.
      </Copy>
      <H2>Security Architecture & Monitoring</H2>
      <Copy styleLevel={2}>
        <BulletList>
          <li>
            <Copy styleLevel={2}>
              <CopyColoredSpan>JobsBoard</CopyColoredSpan> is hosted on AWS. All data is stored in the Ireland region.
            </Copy>
          </li>
          <li>
            <Copy styleLevel={2}>
              All relevant production log entries are stored remotely, with pattern matching and alerts for malicious
              intent, as well as unexpected crashes, exceptions and other error conditions.
            </Copy>
          </li>
        </BulletList>
      </Copy>
      <H2>JobsBoard AI</H2>
      <Copy styleLevel={2}>
        Empowering your workflow with AI, <CopyColoredSpan>JobsBoard</CopyColoredSpan> uses OpenAI's technology,
        leveraging GPT-4 and GPT-3.5 Turbo to offer intelligent, AI-powered tools. And we ensure this power comes
        without a compromise on data security.
      </Copy>
      <H2>General Data Protection Regulation (GDPR Compliance)</H2>
      <Copy styleLevel={1}>How Can I Access and Export My Personal Data?</Copy>
      <Copy styleLevel={2}>To have your personal data exported, please contact us.</Copy>
      <Copy styleLevel={1}>Retention of Data</Copy>
      <Copy styleLevel={2}>
        We will retain your personal data only for as long as is necessary for the purposes set out in this Privacy
        Policy. We will retain and use your personal data to the extent necessary to comply with our legal obligations
        (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and
        enforce our legal agreements and policies.
      </Copy>
    </>
  );
};

export default Security;
